.alerts_container {
  z-index: 99999;
}

.actions_container {
  z-index: 99999;
}

.close {
  cursor: pointer;
  margin-top: 6px;
  margin-right: 5px;
}

.alert_body {
  font-size: 13px;
  color: #222;
  line-height: 20px;
  align-items: start;
}