.message {
  max-height: 150px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.button {
  cursor: pointer;
  width: 100%;
  background-color: #222;
  color: white;
  border: none;
  padding: 7.5px 8px;
  border-radius: 5px;
  margin-top: 10px;
  font-size: 13px;
}

.container {
  display: flex;
}

.icon {
  display: flex;
}


.content {
  width: 100%;
  padding: 0px 10px;
  line-height: 20px;
  font-size: 13px;
  color: #222;
}
